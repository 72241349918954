/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ShippingStatusTag.vue?vue&type=template&id=25d481ff"
import script from "./ShippingStatusTag.vue?vue&type=script&lang=ts&setup=true"
export * from "./ShippingStatusTag.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Tag: require('/code/src/components/Tag.vue').default})
