/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2eb221d1"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=2eb221d1&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loading: require('/code/src/components/Loading.vue').default,Input: require('/code/src/components/input/Input.vue').default,DateInput: require('/code/src/components/input/DateInput.vue').default,FloatingTooltip: require('/code/src/components/tooltip/FloatingTooltip.vue').default,ShippingStatusTag: require('/code/src/components/widgets/ShippingStatusTag.vue').default,LinkText: require('/code/src/components/text/LinkText.vue').default,TextButton: require('/code/src/components/button/TextButton.vue').default,AddressLabel: require('/code/src/components/widgets/AddressLabel.vue').default,CustomizableHeaderTable: require('/code/src/components/table/CustomizableHeaderTable.vue').default,ContentArea: require('/code/src/components/ContentArea.vue').default})
