var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    directives: [{
      name: "resize-observer",
      rawName: "v-resize-observer",
      value: _setup.onResize,
      expression: "onResize"
    }]
  }, [_c('div', {
    staticClass: "tw-grid tw-overflow-hidden tw-border tw-border-border tw-rounded-lg",
    class: {
      'tw-grid-cols-12': _setup.rows === 4,
      'tw-grid-cols-6': _setup.rows === 2,
      'tw-grid-cols-3': _setup.rows === 1
    }
  }, _vm._l(_setup.data, function (item, i) {
    return _c('div', {
      key: "item".concat(i),
      staticClass: "tw-flex tw-items-center tw-justify-center tw-py-1 tw-px-2 tw-min-h-14 tw-whitespace-nowrap tw-border-r tw-border-b tw-border-border -tw-mr-[1px] -tw-mb-[1px]",
      class: {
        'tw-col-span-3': Math.floor(_setup.data.length / _setup.rows) * _setup.rows > i,
        'tw-col-span-4': _setup.rows === 4 && _setup.data.length % _setup.rows === 3 && Math.floor(_setup.data.length / _setup.rows) * _setup.rows <= i,
        'tw-col-span-6': (_setup.rows === 4 && _setup.data.length % _setup.rows === 2 || _setup.rows === 2 && _setup.data.length % _setup.rows === 1) && Math.floor(_setup.data.length / _setup.rows) * _setup.rows <= i,
        'tw-col-span-12': _setup.rows === 4 && _setup.data.length % _setup.rows === 1 && Math.floor(_setup.data.length / _setup.rows) * _setup.rows <= i,
        'tw-flex-col': _setup.width < 640
      }
    }, [_c('SmallLabel', {
      staticClass: "tw-px-1",
      attrs: {
        "label": item[0]
      }
    }), _vm._v(" "), _vm.dummy ? _c('SkeletonLoader', {
      staticClass: "tw-px-1",
      staticStyle: {
        "width": "80px",
        "height": "auto"
      },
      attrs: {
        "text": "h6"
      }
    }) : _c('div', {
      staticClass: "tw-text-h6 tw-px-1",
      domProps: {
        "textContent": _vm._s(item[1])
      }
    })], 1);
  }), 0)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };