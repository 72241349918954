import { VChip } from 'vuetify/lib/components/VChip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VChip, _vm._b({
    staticClass: "px-2",
    style: _setup.dottedOutlineStyle,
    attrs: {
      "label": "",
      "small": ""
    }
  }, 'v-chip', _setup.attributes, false), [_c('span', {
    staticClass: "text-subtitle-3",
    style: {
      letterSpacing: _vm.letterSpacing ? "".concat(_vm.letterSpacing, "em") : undefined,
      marginRight: _vm.letterSpacing ? "-".concat(_vm.letterSpacing, "em") : undefined
    }
  }, [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };