import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import Ripple from 'vuetify/lib/directives/ripple';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c(VToolbar, {
    staticClass: "elevation-0 transparent media-toolbar"
  }, [_c(VToolbarTitle, [_c('h5', [_vm._v(_vm._s(_vm.$t('orderList')))])]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-outline-primary tw-relative",
    attrs: {
      "type": "button",
      "disabled": _vm.loading.csv
    },
    on: {
      "click": _vm.onClickDownloadCsv
    }
  }, [_c('Loading', {
    attrs: {
      "value": _vm.loading.csv
    }
  }), _vm._v("\n      " + _vm._s(_vm.$t('orders.downloadCsv')) + "\n    ")], 1)], 1), _vm._v(" "), _c('ContentArea', [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('Input', {
    attrs: {
      "label": _vm.$t('keyword'),
      "clearable": "",
      "prepend-inner-icon": "mdi-magnify",
      "placeholder": _vm.$t('placeholder.keyword.orders')
    },
    model: {
      value: _vm.filterOptions.keywordText,
      callback: function callback($$v) {
        _vm.$set(_vm.filterOptions, "keywordText", $$v);
      },
      expression: "filterOptions.keywordText"
    }
  })], 1)], 1), _vm._v(" "), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('Input', {
    attrs: {
      "disabled": _vm.filterOptions.onlyNeedAction,
      "type": "autocomplete",
      "multiple": "",
      "items": _vm.currentTeam.users,
      "item-text": "name",
      "item-value": "email",
      "label": _vm.$t('createBy')
    },
    model: {
      value: _vm.filterOptions.createdBy,
      callback: function callback($$v) {
        _vm.$set(_vm.filterOptions, "createdBy", $$v);
      },
      expression: "filterOptions.createdBy"
    }
  })], 1), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('DateInput', {
    attrs: {
      "value": [_vm.filterOptions.orderBeforeDate, _vm.filterOptions.orderAfterDate],
      "type": "range",
      "max-date": _vm.$iso8601(_vm.$nextYear),
      "label": _vm.$t('orderDate')
    },
    on: {
      "input": function input($event) {
        _vm.filterOptions.orderBeforeDate = $event === null || $event === void 0 ? void 0 : $event[0];
        _vm.filterOptions.orderAfterDate = $event === null || $event === void 0 ? void 0 : $event[1];
      }
    }
  })], 1), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('DateInput', {
    attrs: {
      "value": [_vm.filterOptions.beforeDate, _vm.filterOptions.afterDate],
      "type": "range",
      "max-date": _vm.$iso8601(_vm.$nextYear),
      "label": _vm.$t('desiredArrivalOrIssueDate')
    },
    on: {
      "input": function input($event) {
        _vm.filterOptions.beforeDate = $event === null || $event === void 0 ? void 0 : $event[0];
        _vm.filterOptions.afterDate = $event === null || $event === void 0 ? void 0 : $event[1];
      }
    }
  })], 1), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('Input', {
    attrs: {
      "disabled": _vm.filterOptions.onlyNeedAction || _vm.filterOptions.onlyAssigned,
      "type": "select",
      "multiple": "",
      "items": _vm.statusItem,
      "item-text": "name",
      "item-value": "id",
      "label": _vm.$t('status'),
      "placeholder": _vm.$t('placeholder.status')
    },
    scopedSlots: _vm._u([{
      key: "label.prepend",
      fn: function fn() {
        return [_c('FloatingTooltip', {
          attrs: {
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on;
              return [_c(VIcon, _vm._g({
                staticClass: "ml-1",
                attrs: {
                  "small": ""
                }
              }, on), [_vm._v("mdi-help-circle")])];
            }
          }])
        }, [_vm._v(" "), _c('pre', {
          staticClass: "text-body-2"
        }, [_vm._v(_vm._s(_vm.$t('shippingStatusInfo')))])])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filterOptions.selectStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.filterOptions, "selectStatus", $$v);
      },
      expression: "filterOptions.selectStatus"
    }
  })], 1)], 1), _vm._v(" "), _c(VDivider, {
    staticClass: "mt-6"
  }), _vm._v(" "), _c('OrderStats', {
    staticClass: "my-6",
    attrs: {
      "stats": _vm.stats,
      "dummy": _vm.loading.stats
    }
  }), _vm._v(" "), _vm.hasAssignedOrder ? _c('div', {
    staticClass: "d-flex align-center justify-space-between py-3"
  }, [_c('div', {
    staticClass: "text-body-1 primary--text d-flex"
  }, [_c(VIcon, {
    staticClass: "mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-bell-outline")]), _vm._v("\n        " + _vm._s(_vm.$t('orders.hasAssignedOrder')) + "\n      ")], 1), _vm._v(" "), _c(VCheckbox, {
    attrs: {
      "label": _vm.$t('orders.showOnlyAssignedOrders'),
      "hide-details": ""
    },
    model: {
      value: _vm.filterOptions.onlyAssigned,
      callback: function callback($$v) {
        _vm.$set(_vm.filterOptions, "onlyAssigned", $$v);
      },
      expression: "filterOptions.onlyAssigned"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.hasRejectedOrder ? _c('div', {
    staticClass: "d-flex align-center justify-space-between py-3"
  }, [_c('div', {
    staticClass: "text-body-1 primary--text d-flex"
  }, [_c(VIcon, {
    staticClass: "mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-bell-outline")]), _vm._v("\n        " + _vm._s(_vm.$t('orders.hasRejectedOrder')) + "\n      ")], 1), _vm._v(" "), _c(VCheckbox, {
    attrs: {
      "label": _vm.$t('orders.showOnlyRejectedOrders'),
      "hide-details": ""
    },
    model: {
      value: _vm.filterOptions.onlyNeedAction,
      callback: function callback($$v) {
        _vm.$set(_vm.filterOptions, "onlyNeedAction", $$v);
      },
      expression: "filterOptions.onlyNeedAction"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('CustomizableHeaderTable', {
    class: {
      'select-mode': _vm.filterOptions.onlyAssigned
    },
    attrs: {
      "sort-desc": ['shipping_date'],
      "headers": [{
        text: 'ステータス',
        value: 'get_status_display'
      }, {
        text: 'キャンペーン名',
        value: 'campaign.name',
        multiLine: true
      }, {
        text: '氏名',
        value: 'full_name',
        hideable: false
      }, {
        text: '敬称',
        value: 'contact.title',
        empty: _vm.$t('notSet')
      }, {
        text: '会社名',
        value: 'contact.account.name',
        multiLine: true,
        empty: _vm.$t('notSet')
      }, {
        text: 'Webサイト',
        value: 'contact.account.site_url',
        multiLine: true,
        empty: _vm.$t('notSet')
      }, {
        text: '部署',
        value: 'contact.department',
        empty: _vm.$t('notSet')
      }, {
        text: '役職',
        value: 'contact.job_title',
        empty: _vm.$t('notSet')
      }, {
        text: '配送先住所',
        value: 'shipping_address_snapshot',
        ellipsis: false,
        empty: _vm.$t('notSet')
      }, {
        text: 'オーダー作成者',
        value: 'created_by.name'
      }, {
        text: 'オーダー発注日',
        value: 'order_date',
        transform: _vm.$date,
        empty: _vm.$t('notOrder')
      }, {
        text: 'オーダー作成日',
        value: 'created_at',
        transform: _vm.$date
      },
      // TODO APIの対応が必要 { text: 'WOWリンク', value: '', empty: $t('none') },
      // TODO APIの対応が必要 { text: 'QR遷移先URL', value: '', empty: $t('none') },
      {
        text: 'タッチ名',
        value: 'touch_name',
        multiLine: true,
        empty: _vm.$t('none')
      },
      // TODO APIの対応が必要 { text: '商品名', value: 'order_line_items[0].name', multiLine: true },
      // TODO APIの対応が必要 { text: '商品バリエーション', value: '', multiLine: true },
      // TODO APIの対応が必要 { text: '商品価格', value: '', align: 'center', multiLine: true },
      {
        text: '到着希望日/発行日',
        value: 'shipping_date'
      }, {
        text: 'QR反響/クリック',
        value: 'touched_at',
        empty: _vm.$t('none')
      }, {
        text: 'コスト',
        value: 'price',
        align: 'center',
        transform: _vm.$price
      }, {
        text: 'コメント数',
        value: 'comment_count',
        align: 'center'
      }, {
        text: '承認者',
        value: 'authorizer',
        getter: function getter(val) {
          var _val$authorizer$name, _val$authorizer, _val$assigned_to;
          return (_val$authorizer$name = (_val$authorizer = val.authorizer) === null || _val$authorizer === void 0 ? void 0 : _val$authorizer.name) !== null && _val$authorizer$name !== void 0 ? _val$authorizer$name : (_val$assigned_to = val.assigned_to) === null || _val$assigned_to === void 0 ? void 0 : _val$assigned_to.name;
        },
        empty: _vm.$t('notSet')
      }, {
        text: '所有者(担当者)',
        value: 'contact.owner.name',
        empty: _vm.$t('notSet')
      }],
      "default-headers": ['氏名', '会社名', '部署', '役職', 'オーダー作成者', 'オーダー発注日', 'タッチ名', '到着希望日/発行日', 'ステータス', 'QR反響/クリック', 'コスト', 'コメント数'],
      "items": _vm.datatable_items,
      "item-key": "id",
      "loading": _vm.loading.orders,
      "options": _vm.tableOptions,
      "server-items-length": _vm.totalItems,
      "disable-sort": true,
      "select-disable-key": "nonApprovable",
      "select-disable-message": _vm.$t('warning.nonApprovableOrder'),
      "show-select": _vm.filterOptions.onlyAssigned,
      "hide-default-header": _vm.selectedItems.length > 0
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      },
      "click:row": function clickRow(item, _, event) {
        return _vm.onClickItem(item, event);
      },
      "auxclick:row": function auxclickRow(event, row) {
        return _vm.onClickItem(row.item, event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item.get_status_display",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('ShippingStatusTag', {
          attrs: {
            "text": item.get_status_display,
            "status": item.status
          }
        })];
      }
    }, {
      key: "item.campaign.name",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.campaign ? _c('LinkText', {
          attrs: {
            "to": "/campaign/".concat(item.campaign.id)
          }
        }, [_vm._v("\n          " + _vm._s(item.campaign.name) + "\n        ")]) : _vm._e()];
      }
    }, _vm.selectedItems.length > 0 ? {
      key: "header",
      fn: function fn(_ref4) {
        var on = _ref4.on,
          props = _ref4.props;
        return [_c('thead', {
          staticClass: "v-data-table-header"
        }, [_c('tr', [_c('th', {
          staticClass: "overflow-visible",
          attrs: {
            "width": "1px"
          }
        }, [_c('div', {
          staticClass: "d-flex"
        }, [_c(VSimpleCheckbox, {
          directives: [{
            def: Ripple,
            name: "ripple",
            rawName: "v-ripple"
          }],
          staticClass: "v-data-table__checkbox",
          attrs: {
            "indeterminate": props.someItems && !props.everyItem,
            "value": props.someItems
          },
          on: {
            "input": on['toggle-select-all']
          }
        }), _vm._v(" "), _c('span', {
          staticClass: "secondary-text--text text-subtitle-2 p-absolute",
          staticStyle: {
            "left": "38px"
          }
        }, [_vm._v("\n                  " + _vm._s(_vm.$t('selected', [_vm.selectedItems.length])) + "\n                ")])], 1)]), _vm._v(" "), _c('th', {
          staticStyle: {
            "overflow": "initial"
          },
          attrs: {
            "colspan": props.headers.length - 1
          }
        }, [_vm.filterOptions.onlyAssigned ? [_c('div', {
          staticClass: "d-flex"
        }, [_c(VSpacer), _vm._v(" "), _c('div', {
          staticClass: "d-flex",
          staticStyle: {
            "position": "sticky",
            "right": "0"
          }
        }, [_c('TextButton', {
          attrs: {
            "value": _vm.$t('orders.allApprove'),
            "icon": "mdi-check",
            "disabled": _vm.loading.approve
          },
          on: {
            "click": function click($event) {
              return _vm.orderApprove(true);
            }
          }
        }), _vm._v(" "), _c(VDivider, {
          staticClass: "mx-4",
          attrs: {
            "vertical": ""
          }
        }), _vm._v(" "), _c('TextButton', {
          attrs: {
            "value": _vm.$t('orders.allCancel'),
            "icon": "mdi-close",
            "color": "error",
            "disabled": _vm.loading.approve
          },
          on: {
            "click": function click($event) {
              return _vm.orderApprove(false);
            }
          }
        })], 1)], 1)] : _vm._e()], 2)])])];
      }
    } : null, {
      key: "item.full_name",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.contact ? _c('LinkText', {
          attrs: {
            "to": item.contact.id ? "/contact/".concat(item.contact.id) : null
          }
        }, [_vm._v("\n          " + _vm._s(_vm.$fullName(item.contact)) + "\n        ")]) : _vm._e()];
      }
    }, {
      key: "item.touch_name",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [item.order_line_items.length > 0 ? _c('LinkText', {
          attrs: {
            "to": "/touch/".concat(item.order_line_items[0].touch)
          }
        }, [_vm._v("\n          " + _vm._s(item.order_line_items[0].name) + "\n        ")]) : _vm._e()];
      }
    }, {
      key: "item.shipping_date",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [!item.is_egift || item.status === 9 ? [_vm._v("\n          " + _vm._s(_vm.$date(item.shipping_date)) + "\n        ")] : _c('div', {
          staticClass: "text-caption disabled-text--text"
        }, [_vm._v("\n          " + _vm._s(_vm.$t('notIssue')) + "\n        ")])];
      }
    }, {
      key: "item.contact.account.name",
      fn: function fn(_ref8) {
        var _item$contact, _item$contact2;
        var item = _ref8.item;
        return [(_item$contact = item.contact) !== null && _item$contact !== void 0 && _item$contact.account ? _c('LinkText', {
          attrs: {
            "text": item.contact.account.name,
            "to": item.contact.account.id ? "/accounts/".concat(item.contact.account.id) : null
          }
        }) : (_item$contact2 = item.contact) !== null && _item$contact2 !== void 0 && _item$contact2.crm_company_name ? [_vm._v("\n          " + _vm._s(item.contact.crm_company_name) + "\n        ")] : _vm._e()];
      }
    }, {
      key: "item.contact.account.site_url",
      fn: function fn(_ref9) {
        var _item$contact3, _item$contact3$accoun;
        var item = _ref9.item;
        return [(_item$contact3 = item.contact) !== null && _item$contact3 !== void 0 && (_item$contact3$accoun = _item$contact3.account) !== null && _item$contact3$accoun !== void 0 && _item$contact3$accoun.site_url ? _c('LinkText', {
          attrs: {
            "text": item.contact.account.site_url,
            "to": item.contact.account.site_url
          }
        }) : _vm._e()];
      }
    }, {
      key: "item.shipping_address_snapshot",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [item.is_egift ? _c('span', {
          staticClass: "text-caption disabled-text--text"
        }, [_vm._v("\n          " + _vm._s(_vm.$t('noDelivery')) + "\n        ")]) : item.shipping_address_snapshot ? _c('AddressLabel', {
          attrs: {
            "small": "",
            "detail": "",
            "rows": "",
            "max-width": "200",
            "address": item.shipping_address_snapshot
          }
        }) : _vm._e()];
      }
    }, {
      key: "item.touched_at",
      fn: function fn(_ref11) {
        var _item$order_line_item, _item$order_line_item2, _item$order_line_item3;
        var item = _ref11.item;
        return [item.touched_at ? [_vm._v("\n          " + _vm._s(_vm.$date(item.touched_at)) + "\n          "), _c('br'), _vm._v("\n          " + _vm._s(_vm.$time(item.touched_at)) + "\n        ")] : (_item$order_line_item = item.order_line_items) !== null && _item$order_line_item !== void 0 && (_item$order_line_item2 = _item$order_line_item[0]) !== null && _item$order_line_item2 !== void 0 && (_item$order_line_item3 = _item$order_line_item2.line_item_lp) !== null && _item$order_line_item3 !== void 0 && _item$order_line_item3.opened_at ? [_vm._v("\n          " + _vm._s(_vm.$date(item.order_line_items[0].line_item_lp.opened_at)) + "\n          "), _c('br'), _vm._v("\n          " + _vm._s(_vm.$time(item.order_line_items[0].line_item_lp.opened_at)) + "\n        ")] : _vm._e()];
      }
    }, {
      key: "item.comment_count",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c(VIcon, {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-comment-outline")]), _vm._v("\n        " + _vm._s(item.comment_count) + "\n      ")];
      }
    }], null, true),
    model: {
      value: _vm.selectedItems,
      callback: function callback($$v) {
        _vm.selectedItems = $$v;
      },
      expression: "selectedItems"
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };