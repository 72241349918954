var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('Tag', _vm._b({
    attrs: {
      "text": _vm.text
    }
  }, 'Tag', _setup.attributes, false));
};
var staticRenderFns = [];
export { render, staticRenderFns };